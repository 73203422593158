import { IAuthConfig } from '@flint/layouts'
import useLinks from 'hooks/useLinks'
import { FullLogo, MiniLogo } from 'icons'
import React, { useMemo } from 'react'

const authConfig: IAuthConfig = {
  domain: process.env.REACT_APP_AUTH_DOMAIN as string,
  authorizePath: process.env.REACT_APP_AUTH_AUTHORIZE_PATH as string,
  tokenPath: process.env.REACT_APP_AUTH_TOKEN_PATH as string,
  scopes: process.env.REACT_APP_AUTH_SCOPE as string,
  redirectUri: process.env.REACT_APP_AUTH_WEB_CALLBACK_URL as string,
  clientId: process.env.REACT_APP_AUTH_WEB_CLIENT_ID as string,
}

const graphqlUrl = process.env.REACT_APP_GRAPHQL_URL as string

const useAppConfig = () => {
  // links
  const { links } = useLinks()
  // Default app theme
  const theme = useMemo(
    () => ({
      palette: {
        primary: {
          main: '#82bf41',
        },
        secondary: {
          main: '#616060',
          // contrastText: '#000',
        },
      },
    }),
    []
  )
  // flitn config
  const config = {
    auth: authConfig,
    map: {
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    },
    graphql: {
      url: graphqlUrl,
    },
  }
  // drawer config
  const drawerConfig = useMemo(
    () => ({
      toolbar: {
        FullLogo: <FullLogo style={{ height: '50px', width: '100%' }} />,
        LogoIcon: <MiniLogo />,
      },
    }),
    []
  )
  return {
    theme,
    config,
    links,
    drawer: drawerConfig,
  }
}

export default useAppConfig
