import { IFlintConfig } from '@flint/types'
import { resources as i18lResources } from './src/global'
import { FullLogo, MiniLogo } from './src/icons'
import { useLinks as AppLinks } from './src/hooks/useLinks'
import { plainReducers } from './src/store/reducer'

export const flintConfig = (): IFlintConfig => ({
  reducers: plainReducers,
  i18l: {
    resources: i18lResources,
  },
  auth: {
    scopes: process.env.REACT_APP_AUTH_SCOPE,
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    tokenPath: process.env.REACT_APP_AUTH_TOKEN_PATH,
    clientId: process.env.REACT_APP_AUTH_WEB_CLIENT_ID,
    authorizePath: process.env.REACT_APP_AUTH_AUTHORIZE_PATH,
    redirectUri: process.env.REACT_APP_AUTH_WEB_CALLBACK_URL,
  },
  graphQl: {
    uri: process.env.REACT_APP_GRAPHQL_URL,
  },
  layout: {
    theme: {
      palette: {
        primary: {
          main: '#82bf41',
        },
        secondary: {
          main: '#616060',
        },
      },
    },
    drawerToolbar: {
      FullLogo: <FullLogo style={{ height: '50px', width: '100%' }} />,
      LogoIcon: <MiniLogo />,
    },
  },
  google: {
    maps: {
      key: process.env.GOOGLE_MAPS_API_KEY,
    },
  },
  links: AppLinks as any,
})

export default flintConfig
