import { Layout } from '@flint/layouts'

function HowToUse() {
  return (
    <Layout
      pageTitle="كيفية إستخدام الدليل البلدي لمستفيدي الخدمات"
      pageSubTitle="كيفية إستخدام الدليل البلدي لمستفيدي الخدمات"
    >
      <h1>HowToUse</h1>
    </Layout>
  )
}

export default HowToUse
