import { AppProgress, authenticateUserBack } from '@flint/layouts'
import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Path } from 'global'

function AuthCallback() {
  const navigate = useNavigate()

  const authenticate = useCallback(async () => {
    try {
      await authenticateUserBack()
    } catch (error) {
      //
    } finally {
      navigate(Path.HOME)
    }
  }, [navigate])

  useEffect(() => {
    authenticate()
  }, [authenticate])

  return <AppProgress loading />
}

export default AuthCallback
