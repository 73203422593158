import React, { FunctionComponent, useMemo } from 'react'
import { Typography, Grid, Box } from '@material-ui/core'

import { LoadingProgress, MainButton } from '@flint/layouts'
import { FormMode, ILayer, IRecord } from 'global'
import { useDispatch, useSelector } from 'react-redux'
import { deleteRecord } from 'store/layers/layer.async'
import { RootState } from 'store/reducer'
import {
  changeRecordsView,
  setActiveLayer,
  setActiveRecord,
} from 'store/layers'
import clsx from 'clsx'

import useLayer from 'hooks/useLayer'
import { PopupColumn } from './PopupColumn'
import { useStyle } from './RecordPopup.style'

interface IProps {
  layer: ILayer
  record: IRecord
}

export const RecordPopup: FunctionComponent<IProps> = ({
  record,
  layer,
}: IProps) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const { deletingRecord } = useSelector((state: RootState) => state.layer)
  const { userCan } = useLayer()

  const layerTitles = useMemo(() => {
    const titles: Record<string, any> = {}
    const props =
      (layer && layer.jsonSchema && layer.jsonSchema.properties) || {}
    Object.keys(props).forEach((propKey) => {
      titles[propKey] = {
        title: props[propKey].title,
        isSummaryField: props[propKey].isSummaryField,
        isImageField: props[propKey].isImageField,
      }
    })
    return titles
  }, [layer])

  const data = useMemo(() => {
    const recordCols = [] as any[]
    let recordData = (record.data || (record as any).properties) as any
    recordData = (recordData.data && JSON.parse(recordData.data)) || recordData
    console.log('recordData', recordData)
    console.log('layerTitles', layerTitles)

    Object.keys(recordData).forEach((prop: any) => {
      const property = layerTitles[prop]
      console.log('layerTitles', layerTitles)
      console.log('layerTitles', layerTitles)
      console.log('property', property)
      console.log('prop', prop)
      if (property && property.isSummaryField) {
        const rec = {
          title: property.title,
          value: recordData[prop],
          isImageField: property.isImageField,
        }
        recordCols.push(rec)
      }
    })
    return recordCols
  }, [layerTitles, record])

  console.log('data--->', data)

  const onDelete = (_record: IRecord) => {
    dispatch(deleteRecord(_record.id, layer.geometryType, layer.id))
  }
  const onEdit = (_record: IRecord) => {
    dispatch(
      setActiveLayer(layer, () => {
        dispatch(setActiveRecord(_record))
        dispatch(changeRecordsView(FormMode.EDIT))
      })
    )
  }

  return (
    <div className={classes.root}>
      <LoadingProgress loading={deletingRecord}>
        <h1>Loading</h1>
      </LoadingProgress>
      {/* Content */}
      <Box display="flex" alignItems="center" marginBottom={2}>
        <Typography
          style={{
            margin: 0,
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              border: '1px solid',
              borderColor: layer.geometryColor,
              marginLeft: 10,
              borderRadius: 10,
              height: 30,
              width: 30,
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: layer.geometryColor,
                opacity: 0.2,
              }}
            />
          </div>
        </Typography>
        <Typography style={{ margin: 0, fontWeight: 600 }}>
          {/* {((record.createdAt || record.created_at) as unknown as string).split('T')[0]} */}
        </Typography>
        <strong>{record.layer.title}</strong>
      </Box>
      <Grid container alignItems="center" wrap="wrap">
        {data.map((item: any, index) => (
          <PopupColumn
            title={item.title}
            body={item.value}
            key={index}
            isImageField={item.isImageField}
          />
        ))}
      </Grid>
      {/* Footer */}
      <Grid
        container
        spacing={2}
        style={{ marginTop: 10 }}
        className={clsx({ [classes.hide]: layer.isGtLayer })}
      >
        {userCan('EDIT_RECORD') && (
          <Grid item sm={6}>
            <MainButton
              color="primary"
              variant="contained"
              fullWidth
              disableElevation
              style={{ borderRadius: 20 }}
              onClick={() => onEdit(record)}
              // disabled={deleting && recordDeleted === record.id}
            >
              تعديل
            </MainButton>
          </Grid>
        )}
        {userCan('DELETE_RECORD') && (
          <Grid item sm={6}>
            <MainButton
              color="secondary"
              style={{ borderRadius: 20 }}
              variant="contained"
              disableElevation
              fullWidth
              // disabled={deleting && recordDeleted === record.id}
              onClick={() => onDelete(record)}
            >
              حذف
            </MainButton>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default RecordPopup
