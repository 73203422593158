import { DetailsWrapper } from '@flint/layouts'
import { DetailsWrapperProps } from '@flint/layouts/build/web/components/DetailsWrapper/DetailsWrapper.interface'
import { NoResult } from 'NoResult'
import { FormMode, ILayer } from 'global'
import { useMemo } from 'react'
import { Divider } from '@material-ui/core'

import { Hide } from 'components/Hide'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import { LayerRecords } from './LayerRecords'
import { CreateRecordsButton } from './CreateRecrodsButton'
import { LeftComponent } from './LeftComponent'
import { RecordFormSchema } from './RecordFormSchema'

interface DetailsViewerProps {
  layers: ILayer[]
  loading: boolean
  selectedLayer: ILayer
}

function DetailsViewer({ layers, loading, selectedLayer }: DetailsViewerProps) {
  const {
    selectdRecord,
    recordFormMode,
    loadingMessage,
    isSearchMode,
    isInfinitScroll,
  } = useSelector((state: RootState) => state.layer)

  // wrapperProps
  const wrapperProps: DetailsWrapperProps = useMemo(() => {
    let title = 'السجل'
    if (selectdRecord && !Object.keys(selectdRecord).length) {
      title = 'الطبقة'
    }
    return {
      isDataLength: true,
      header: {
        title: `تفاصيل ${title}`,
        subTitle: `إدخال و عرض تفاصيل ${title}`,
        LeftComponent,
      },
    }
  }, [selectdRecord])

  // change status if no result message
  const status = useMemo(() => (loading ? 'LOADING' : 'EMPTY'), [loading])
  const isSelectedRecord =
    !!selectdRecord && !!Object.keys(selectdRecord).length
  return (
    <DetailsWrapper {...wrapperProps}>
      <Divider />
      {/* Where there are no layers to display or still loading the layers from the server */}
      <NoResult
        status={status}
        open={!layers.length || (loading && !isInfinitScroll)}
        message={loadingMessage}
      />
      <Hide
        when={[
          loading && !isInfinitScroll,
          isSelectedRecord,
          recordFormMode === FormMode.CREATE,
        ]}
      >
        <CreateRecordsButton active={!!layers.length && !isSearchMode} />
        <LayerRecords layer={selectedLayer} />
      </Hide>
      {/* show records form schema */}
      <Hide when={[loading && !isInfinitScroll]}>
        {(!isSelectedRecord && recordFormMode !== FormMode.CREATE) ||
          (selectedLayer.records && <RecordFormSchema />)}
      </Hide>
    </DetailsWrapper>
  )
}

export default DetailsViewer
