import { flintReducers } from '@flint/layouts'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { plainReducers } from './reducer'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const combinedReducer = combineReducers({
  ...plainReducers,
  ...flintReducers,
})

export default createStore(
  combinedReducer,
  composeEnhancers(applyMiddleware(thunk))
)
