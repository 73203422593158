import { gql } from '@apollo/client'

/**
 * Muation for create a new layer
 */
export const CREATE_LAYER = gql`
  mutation CreateLayer($layerData: CreateLayerInput!) {
    createLayer(layerData: $layerData) {
      layer {
        id
        jsonSchema
        name
        title
        geometryType
        geometryColor
        description
        mobileUiJsonSchema
        webUiJsonSchema
      }
    }
  }
`
/**
 * Muation for create a new layer
 */
export const CREATE_LAYER_FROM_DATASET = gql`
  mutation CreateLayer($layerData: CreateLayerFromDatasetInput!) {
    createLayerFromDataset(layerData: $layerData) {
      jsonschema
      layer {
        id
        jsonSchema
        name
        title
        geometryType
        geometryColor
        description
      }
    }
  }
`

export const UPDATE_LAYER = gql`
  mutation updateLayer($layerData: UpdateLayerInput!) {
    updateLayer(layerData: $layerData) {
      layer {
        id
        jsonSchema
        name
        title
        geometryType
        geometryColor
        description
      }
    }
  }
`
/**
 * Muation for Delete a layer
 */
export const DELETE_LAYER = gql`
  mutation DeleteLayer($layerId: Int!) {
    deleteLayer(layerId: $layerId) {
      status
    }
  }
`

// Create a new record

export const CREATE_RECORD = gql`
  mutation CreateRecord($recordData: CreateGeometryRecordInput!) {
    createRecord(recordData: $recordData) {
      record {
        ... on PointRecordType {
          id
          data
          createdAt
          geometry
          updatedAt
        }
        ... on PolygonRecordType {
          id
          createdAt
          data
          geometry
          updatedAt
        }
        ... on LineStringRecordType {
          id
          createdAt
          data
          geometry
          updatedAt
        }
      }
    }
  }
`
// UPDATE RECORD

export const UPDATE_RECORD = gql`
  mutation UpdateRecord($recordData: UpdateGeometryRecordInput!) {
    updateRecord(recordData: $recordData) {
      record {
        ... on PointRecordType {
          id
          createdAt
          data
          geometry
          updatedAt
        }
        ... on PolygonRecordType {
          id
          createdAt
          data
          geometry
          updatedAt
        }
        ... on LineStringRecordType {
          id
          createdAt
          data
          geometry
          updatedAt
        }
        ... on MultiPointRecordType {
          id
          createdAt
          data
          geometry
          updatedAt
        }
        ... on MultiPolygonRecordType {
          id
          createdAt
          data
          geometry
          updatedAt
        }
        ... on MultiLineStringRecordType {
          id
          createdAt
          data
          geometry
          updatedAt
        }
      }
    }
  }
`

// Delete Record
export const DELETE_RECORD = gql`
  mutation DeleteRecord($recordInput: RecordInputType!) {
    deleteRecord(recordInput: $recordInput) {
      status
    }
  }
`

// Delete Record
export const INSERT_RECORDS_FROMDATASET = gql`
  mutation InsertRecordsFromDataset(
    $layerId: Int!
    $columns: ColumnsInputType
  ) {
    insertRecordsFromDataset(layerId: $layerId, columns: $columns) {
      publishStatus
    }
  }
`

export const GENERATE_EDA_REPORT = gql`
  mutation GenerateEdaReport($layerId: Int!) {
    generateEdaReport(layerId: $layerId) {
      publishStatus
    }
  }
`

export const UPLOAD_FILES = gql`
  mutation UploadFiles(
    $imageFile: Upload!
    $context: ImageUploadContext!
    $extraArgs: JsonData!
  ) {
    uploadImage(
      imageFileInput: {
        imageFile: $imageFile
        context: $context
        extraArgs: $extraArgs
      }
    ) {
      errors
      url
    }
  }
`
