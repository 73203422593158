import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './styles/main.scss'

/**
 * Disable console.[log,debug,info] in production
 */
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.debug = () => {}
  console.info = () => {}
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
