import { combineReducers } from 'redux'
import { getMappedReducers, IState as FlintState } from '@flint/layouts'
import formReducer, { IFormState } from './form'
import layerReducer, { ILayerState } from './layers'
import { layoutReducer, ILayoutState } from './layout/layout'
import orgReducer from './org'
import { IOrgState } from './org/org.reducer'

export interface RootState extends FlintState {
  org: IOrgState
  form: IFormState
  layer: ILayerState
  layout: ILayoutState
}

export type GetStateFn = () => RootState

export const plainReducers = getMappedReducers({
  prefix: '',
  reducers: {
    org: orgReducer,
    form: formReducer,
    layer: layerReducer,
    layout: layoutReducer,
  },
})

export const rootReducer = combineReducers(plainReducers)

export default rootReducer
