import { ILink } from '@flint/layouts'
import { Path } from 'global'
import { useLocation, useNavigate } from 'react-router'
import { ClientInfo } from 'components/ClientInfo'
import { DashboardIcon } from '../icons'

export const useLinks = () => {
  const navigate = useNavigate()
  const router = useLocation()

  const buildDynamicProps = (path: Path): Partial<ILink> => {
    return {
      isActive: router.pathname === path,
      link: path,
      onClick: async () => navigate(path),
    }
  }

  const links: any[] = [
    {
      component: ClientInfo,
      showOn: ['drawerOpened', 'auth'],
    },
    {
      title: 'الرئيسية',
      IconComponent: DashboardIcon,
      ...buildDynamicProps(Path.DASHBOARD),
    },
  ]

  return {
    links,
  }
}

export default useLinks
