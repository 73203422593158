import { makeStyles } from '@material-ui/core'

const INPUT_WIDTH = 300

export const useStyle = makeStyles((theme) => ({
  root: {
    right: `calc(50% - ${INPUT_WIDTH / 2}px)!important`,
  },
  control: {
    border: 'none!important',
  },
  results: {
    margin: '0 auto',
    right: 0,
    left: 0,
    position: 'absolute',
    // display: 'none',
    backgroundColor: 'white',
    padding: 0,
    listStyleType: 'none',
    border: '1px solid #d2d2d2',
    borderTop: 0,
    boxSizing: 'border-box',
    zIndex: 99999,
    flexDirection: 'column',
    '&::after': {
      content: '""',
      padding: '1px 1px 1px 0',
      height: 18,
      boxSizing: 'border-box',
      textAlign: 'right',
      display: 'block',
      backgroundImage:
        'url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png)',
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '120px 14px',
      marginRight: 10,
    },
  },
  input: {
    // height: 40,
    backgroundColor: theme.palette.common.white,
    minWidth: INPUT_WIDTH,
    border: 'none',
    fontFamily: "'Almarai', sans-serif!important",
  },
}))
export default useStyle
