import React from 'react'
import { FlintProvider, setupLocalization } from '@flint/layouts'
import { resources } from 'global'
import { useLocation } from 'react-router'
import { store } from '../../store'
import useAppConfig from './useAppConfig'
import AppWrapper from './AppWrapper'

setupLocalization(resources)

function AppContainer() {
  const { links, drawer, theme, config } = useAppConfig()
  const location = useLocation()
  return (
    <FlintProvider
      store={store as any}
      drawer={drawer}
      links={links}
      config={config}
      theme={theme}
      router={location}
    >
      <AppWrapper />
    </FlintProvider>
  )
}

export default AppContainer
