// /* global NodeJS */
import React, { useCallback, useEffect, useMemo } from 'react'
import {
  AppProgress,
  // authenticateUserBack,
  // authenticateUser,
  IState,
  useAuth,
  authenticateUserAsync,
} from '@flint/layouts'
import { Path } from 'global'
import { FullLogo } from 'icons'
import { DashboardPage } from 'pages'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router'
import { HowToUse } from 'pages/HowToUse'
import NoOrganization from 'components/NoOrganization'
import useOrg from 'hooks/useOrg'
import { fetchUserOrgs } from 'store/org'

// let protectedRoutesTimer: NodeJS.Timeout

function ProtectedRoutes() {
  // const location = useLocation()
  // show organization when it is loaded

  const dispatch = useDispatch()
  // use query for fetch user's organization
  const { organizations, loadingOrgs } = useOrg()
  // get authenticatoin status form flint
  const { isAuthenticated, authenticating } = useAuth()
  // fetch current layout config form flint
  const drawerToolbarState = useSelector(
    (state: IState) => state.flint.layout.drawerToolbar
  )

  const authenticate = () => {
    dispatch(authenticateUserAsync(true))
  }

  // Update app logo with the organization logo
  const updateOrgLogo = useCallback(() => {
    // update the current drawer toolbar state to obtain the organizaton logo
    const payload = { ...drawerToolbarState }
    payload.FullLogo = <FullLogo />

    dispatch({
      reducer: 'flint.layout',
      type: 'drawerToolbar',
      payload: drawerToolbarState,
    })
  }, [drawerToolbarState, dispatch])

  const hasNoOrg = useMemo(() => {
    if (loadingOrgs) return false
    if (!organizations || !organizations.length) return true
  }, [organizations, loadingOrgs])

  // return to home page if is not authenticated
  useEffect(() => {
    authenticate()
  }, [])

  useEffect(() => {
    updateOrgLogo()
  }, [updateOrgLogo])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserOrgs())
    }
  }, [dispatch, isAuthenticated])

  // show laoding while authenticating
  if (authenticating || loadingOrgs || !isAuthenticated)
    return <AppProgress loading />

  if (hasNoOrg) return <NoOrganization />
  return (
    <Routes>
      <Route path="dashboard/*">
        <Route path="layer/:layerId/*">
          <Route path="record/:recordId" element={<DashboardPage />} />
          <Route path="" element={<DashboardPage />} />
        </Route>
        <Route path="" element={<DashboardPage />} />
      </Route>
      <Route path={Path.HOW_TO_USE} element={<HowToUse />} />
    </Routes>
  )
}

export default ProtectedRoutes
