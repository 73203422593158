import { CreateLayerRequest } from 'global'
import { useFormikContext } from 'formik'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import { cloneDeep } from 'lodash'
import { uniquePropName } from 'utils/jsonSchema'
import { FormRow } from './FormRow'

const initialForm = {
  title: '',
  propKey: '',
  type: 'string' as any,
  isSummaryField: false,
}

function UiView() {
  const { layerForm } = useSelector((state: RootState) => state.layer)

  const { values, setFieldValue } = useFormikContext<CreateLayerRequest>()
  const { jsonSchema } = values

  const onAppend = () => {
    const schmea = cloneDeep(jsonSchema)
    initialForm.propKey = uniquePropName()
    schmea.properties.push(initialForm)
    setFieldValue('jsonSchema', schmea)
  }

  const onRemove = (index: number) => {
    const clonedSchema = cloneDeep(jsonSchema)
    const prop = clonedSchema.properties.splice(index, 1)
    // remove key from required
    if (prop && prop.length) {
      const idx = clonedSchema.required.findIndex((k) => prop[0].propKey === k)
      if (idx >= 0) {
        clonedSchema.required.splice(idx, 1)
      }
    }
    setFieldValue('jsonSchema', clonedSchema)
  }

  const rowsLength = useMemo(() => {
    return jsonSchema.properties.length
  }, [jsonSchema.properties])

  return (
    <div>
      {jsonSchema.properties.map((prop: any, index: number) => (
        <FormRow
          key={prop.propKey}
          formLength={rowsLength}
          disableInteraction={layerForm.isFileUploaded}
          rowIndex={index}
          onAppend={onAppend}
          onRemove={onRemove}
        />
      ))}
    </div>
  )
}

export default UiView
