/* global NodeJS */

import { Button, Grid, Link } from '@material-ui/core'
import React, { Fragment, useEffect } from 'react'
import {
  AppProgress,
  authenticateUserAsync,
  Layout,
  useAuth,
  useTranslation,
} from '@flint/layouts'
import { useNavigate } from 'react-router'
import { Path } from 'global'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import { useStyle } from './Home.style'
import {
  AppleStoreIcon,
  GoogleStoreIcon,
  NavigationPanaIcon,
  FullLogo,
} from '../../icons'

let authPageTimeout: NodeJS.Timeout

function HomePage() {
  const navigate = useNavigate()
  const { isAuthenticated, authenticating } = useAuth()
  const dispatch = useDispatch()
  const classes = useStyle()
  const { t } = useTranslation()

  const authenticate = () => {
    dispatch(authenticateUserAsync(true))
  }

  useEffect(() => {
    if (isAuthenticated) {
      authPageTimeout = setTimeout(() => {
        navigate(Path.DASHBOARD, { replace: true })
      }, 100)
    }
    // cancel event when component gets rerenderd
    return () => {
      if (authPageTimeout) {
        clearTimeout(authPageTimeout)
      }
    }
  }, [isAuthenticated, authenticating, navigate])

  return (
    <Fragment>
      <AppProgress loading={authenticating || isAuthenticated} />
      <div
        className={clsx(classes.root, {
          [classes.hide]: authenticating || isAuthenticated,
        })}
      >
        <Layout
          // className={classes.root}
          requiredAuth={false}
          disableDrawer
          disableToolbar
        >
          <div className={classes.container}>
            <Grid container className={classes.headerContainer}>
              <Grid item xs={12} md={6} className={classes.platformDescription}>
                <div className={classes.platformContent}>
                  <FullLogo className={classes.logo} />

                  <h1 className={classes.headerTitle}>محرك الاستدلال</h1>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                className={classes.headerBannerContainer}
              >
                <NavigationPanaIcon className={classes.headerBanner} />
              </Grid>
            </Grid>

            <Grid container className={classes.footerContainer}>
              <Grid item xs={12} md={6} className={classes.loginContainer}>
                <div className={classes.loginContent}>
                  <div className={classes.loginTitle}>
                    {t('login to the platfrom')}
                  </div>

                  <div className={classes.actionsContainer}>
                    <Button
                      variant="contained"
                      aria-label="login"
                      disableElevation
                      onClick={authenticate}
                      className={classes.loginButton}
                      disabled={isAuthenticated || authenticating}
                    >
                      {isAuthenticated || authenticating
                        ? 'Checking your account'
                        : t('login')}
                    </Button>

                    <Button
                      variant="contained"
                      aria-label="register"
                      disableElevation
                      onClick={authenticate}
                      className={classes.newRegisterButton}
                      disabled={isAuthenticated || authenticating}
                    >
                      {t('new registration')}
                    </Button>
                  </div>

                  <Link href="/privacy" className={classes.termsOfUse}>
                    {t('terms of use and privacy policy')}
                  </Link>
                </div>
              </Grid>

              <Grid item xs={12} md={6} className={classes.appLinkContainer}>
                <div>
                  <div className={classes.downloadTitle}>
                    {t('download app')}
                  </div>

                  <div className={classes.storesContainer}>
                    <Link
                      aria-label="Apple store download"
                      className={classes.storeLink}
                    >
                      <AppleStoreIcon className={classes.appleStoreLogo} />
                    </Link>

                    <Link
                      aria-label="Google store download"
                      className={classes.storeLink}
                    >
                      <GoogleStoreIcon className={classes.googleStoreLogo} />
                    </Link>
                  </div>

                  <div className={classes.copyright}>{t('copyright')}</div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </div>
    </Fragment>
  )
}

export default HomePage
