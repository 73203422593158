import { useEffect, useMemo, useState, useCallback } from 'react'
import { LayersControl, useMap, TileLayer } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import { ILayer } from 'global'

import { addLayerControl, removeLayerControl } from 'store/layers'
import SingleLayerControl from './SingleLayerControl'

function LayersFeature() {
  const { layer } = useSelector((state: RootState) => state)

  const [bbox] = useState('')
  const [oldBbox] = useState('')
  const map = useMap()
  const dispatch = useDispatch()
  const { layers, geoTechLayers } = layer
  const [, setAliasCalled] = useState(false)

  const allLayers = useMemo(() => {
    return layers.concat(geoTechLayers)
  }, [layers, geoTechLayers])

  const findLayerByName = useCallback(
    (name: string): ILayer => {
      console.log('selected layer name', name)
      return allLayers.find((l: ILayer) => l.title === name)
    },
    [allLayers]
  )

  const onBaseLayerChange = useCallback((event: any) => {
    console.log('event', event)
    event.layer.setZIndex(0)
  }, [])
  useEffect(() => {
    map.on('baselayerchange', onBaseLayerChange)
    return () => {
      map.removeEventListener('baselayerchange', onBaseLayerChange)
    }
  }, [onBaseLayerChange, map])

  // listen on any on check
  const onOverlayAdd = useCallback(
    (e: any) => {
      console.log('onOverlayAdd', e)
      const _layer = findLayerByName(e.name)
      console.log('onOverlayAdd', _layer)
      if (!_layer.bboxRecords || oldBbox !== bbox) {
        setAliasCalled(false)
      }
      dispatch(addLayerControl(_layer))
    },
    [findLayerByName, dispatch, oldBbox, bbox]
  )

  // listen on any on check
  const onOverlayRemove = useCallback(
    (e: any) => {
      const _layer = findLayerByName(e.name)
      dispatch(removeLayerControl(_layer.id, _layer.isGtLayer))
    },
    [findLayerByName, dispatch]
  )

  useEffect(() => {
    map.on('overlayadd', onOverlayAdd)
    return () => {
      map.removeEventListener('overlayadd', onOverlayAdd)
    }
  }, [onOverlayAdd, map])

  useEffect(() => {
    map.on('overlayremove', onOverlayRemove)
    return () => {
      map.removeEventListener('overlayremove', onOverlayRemove)
    }
  }, [onOverlayRemove, map])

  return (
    <LayersControl>
      <LayersControl.BaseLayer checked name="OpenStreetMap">
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreet Map</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="OpenTopoMap">
        <TileLayer
          zIndex={0}
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreet Map</a> contributors'
          url="https://tile.opentopomap.org/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Google Maps Hybrid">
        <TileLayer
          zIndex={0}
          attribution='&copy; <a href="http://osm.org/copyright">Google Maps Hybrid</a> contributors'
          url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}?region=SA&language=ar"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Google Maps Roadmap">
        <TileLayer
          zIndex={0}
          attribution='&copy; <a href="http://osm.org/copyright">Google Maps Hybrid</a> contributors'
          url="http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}?region=SA&language=ar"
        />
      </LayersControl.BaseLayer>
      {allLayers.map((layer: ILayer) => (
        <SingleLayerControl key={layer.id} layer={layer} />
      ))}
    </LayersControl>
  )
}
export default LayersFeature
