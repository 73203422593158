import { EVENT_NAMES, ILayer } from 'global'
import eventBus from './eventBus'

export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  const blob = new Blob([ab], { type: mimeString })

  // @ts-ignore
  blob.name = `filename.${mimeString.split('/')[1]}`
  return blob
}

export const isDataURL = (value: string) => {
  if (typeof value !== 'string') return
  const isDataUrl = value.match(/^(data:.*\/.*;)/)
  const isImageURL = value.match(/^http.*\.(jpeg|jpg|gif|png)$/)
  return isDataUrl || isImageURL
}

export const getImageUrl = (value: any) => {
  if (Array.isArray(value)) {
    return value[0]
  }
  return value
}

export const getSearchParams = <T extends object>(): Partial<T> => {
  // server side rendering
  if (typeof window === 'undefined') {
    return {}
  }

  const params = new URLSearchParams(window.location.search)

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop as string) || undefined
    },
  }) as T
}

export const goToLayer = (
  layer: ILayer,
  segment: string = '',
  title: string = ''
) => {
  const layerId = layer.id
  const newPath = `/dashboard/layer/${layerId}${segment}`
  const __title = title || layer.title
  const prevURL = new window.URL(window.location.href)
  window.history.pushState(null, __title, newPath)
  const currentURL = new window.URL(window.location.href)
  eventBus.emit(EVENT_NAMES.PUSH_STATE, { currentURL, prevURL })
}
