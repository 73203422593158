import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ILayer } from 'global'
import { makeStyles, TextField } from '@material-ui/core'

import clsx from 'clsx'
import GeoTable from 'components/GeoTable/GeoTable'

import { useDispatch, useSelector } from 'react-redux'
import { createLayerAction } from 'store/layers/layer.actions'
import { getSummarizedProps, ISummarizedHeadProps } from 'utils/jsonSchema'
import { RootState } from 'store/reducer'
import './LayerRecords.scss'
import { fetchRecords } from 'store/layers'
import { Pagination } from '@material-ui/lab'
import { goToLayer } from 'utils/common'

interface LayerRecordsProps {
  layer: ILayer
}

const useStyle = makeStyles(() => ({
  root: {},
  tableWrapper: {},
  hide: {
    display: 'none',
  },
}))

function LayerRecords({ layer }: LayerRecordsProps) {
  const classes = useStyle()
  const dispatch = useDispatch()
  const [limit] = useState(50)

  const { recordsPage } = useSelector((state: RootState) => state.layer)

  const headers = useMemo((): ISummarizedHeadProps[] => {
    const schema = layer.jsonSchema || {}
    console.log('schema', schema)
    const headers = []
    getSummarizedProps(
      schema,
      (head: { key: string; title: string; isImage: boolean }) => {
        const index = headers.findIndex((h) => h.key === head.key)
        if (index === -1) {
          headers.push(head)
        }
      }
    )
    return headers
  }, [layer])

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(createLayerAction('recordsPage', page))
  }

  const paginate = useCallback(() => {
    const skip = (recordsPage - 1) * limit
    dispatch(fetchRecords(layer, { pageInfo: { offset: skip, limit } }))
    goToLayer(layer)
  }, [recordsPage, limit, dispatch, layer])

  useEffect(() => {
    console.count('called pagination')
    if (layer && Object.keys(layer).length) {
      paginate()
    }
    // eslint-disable-next-line
  }, [recordsPage])

  const goToPage = (e: any) => {
    const value = parseInt(e.target.value, 10)
    if (!value || value > totalPages) return
    if (e.key === 'Enter') {
      dispatch(createLayerAction('recordsPage', value))
    }
  }

  const totalPages = useMemo(() => {
    return Math.ceil(layer.recordsCount / limit)
  }, [layer.recordsCount, limit])

  return (
    <div className={clsx(classes.root, { [classes.hide]: false })}>
      <GeoTable rows={layer.records} headers={headers} />
      <div
        style={{
          marginTop: 10,
          display: layer.records ? 'flex' : 'none',
          alignItems: 'center',
        }}
      >
        <Pagination
          shape="rounded"
          size="medium"
          variant="outlined"
          page={recordsPage}
          count={totalPages}
          onChange={onPageChange}
        />
        {totalPages > 2 && (
          <TextField
            style={{ width: 180 }}
            onKeyPress={goToPage}
            type="number"
            label="اذهب مباشرة للصفحة"
            size="small"
            variant="outlined"
            inputProps={{ min: 1, max: totalPages }}
          />
        )}
      </div>
    </div>
  )
}

export default LayerRecords
