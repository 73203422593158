import React, { useEffect, useRef } from 'react'
import { Button, makeStyles, Tooltip } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import L from 'leaflet'

const useStyle = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 130,
    right: 9,
    zIndex: theme.zIndex.modal - 1,
  },
}))

interface RefetchControlProps {
  onRefresh?: () => void
}

function RefetchControl({ onRefresh }: RefetchControlProps) {
  const buttonRef = useRef()
  const classes = useStyle()

  const refetch = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (onRefresh) {
      onRefresh()
    }
  }

  useEffect(() => {
    L.DomEvent.disableClickPropagation(buttonRef.current)
  }, [buttonRef])

  return (
    <div className={classes.root}>
      <Tooltip title="تحديث البيانات">
        <Button
          ref={buttonRef}
          size="small"
          variant="contained"
          color="secondary"
          onClick={refetch}
          style={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            border: 'none',
            padding: 0,
            minWidth: 'auto',
          }}
        >
          <RefreshIcon fontSize="small" />
        </Button>
      </Tooltip>
    </div>
  )
}

export default RefetchControl
