import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl, { FormControlProps } from '@material-ui/core/FormControl'
import Select, { SelectProps } from '@material-ui/core/Select'
import InputBase from '@material-ui/core/InputBase'
import { useField } from 'formik'

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '16px 12px',
    lineHeight: '19px',
    color: 'rgb(22, 21, 26)',
    fontFamily: ['Almarai'].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      color: 'rgb(22, 21, 26) !important',
    },
  },
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: ['Almarai, sans-serif'].join(','),
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  label: {
    fontSize: 11,
    fontWeight: 'bold',
    color: 'rgb(22, 21, 26)',
    marginTop: '-22px',
  },
}))

interface GSelectProps extends SelectProps {
  items: Array<string | { value: any; text: string }>
  formControlProps?: FormControlProps
  name?: string
  value?: any
}

export function GFSelect({
  label,
  name,
  items,
  placeholder,
  children,
  value,
  formControlProps = { fullWidth: true },
  ...rest
}: GSelectProps) {
  const classes = useStyles()
  const [field] = useField<any>({ name, value, type: 'select' })
  return (
    <FormControl className={classes.root} {...formControlProps}>
      <InputLabel shrink={false} className={classes.label}>
        {label}
      </InputLabel>
      <Select
        fullWidth
        displayEmpty={!!placeholder}
        input={<BootstrapInput className={label ? classes.inputRoot : ''} />}
        {...rest}
        {...field}
      >
        {placeholder && (
          <MenuItem value="" disabled key="abc">
            {placeholder}
          </MenuItem>
        )}
        {children ||
          items.map((item: any) => {
            const text = item && item.hasOwnProperty('text') ? item.text : item
            const itemValue =
              item && item.hasOwnProperty('value') ? item.value : item
            return (
              <MenuItem key={itemValue} value={itemValue}>
                {text}
              </MenuItem>
            )
          })}
      </Select>
    </FormControl>
  )
}
