import { gql } from '@apollo/client'

export const FETCH_ORG = gql`
  query FetchOrg {
    organizationsList {
      id
      userPermissions
      userRoles
      name
      meta
      fullLogo
      abstractLogo
    }
  }
`

export const FETCH_ORGANIZATION_DETAILS = gql`
  query OrganizationDetails($id: Int!) {
    organizationDetails(id: $id) {
      fullLogo
      name
      userPermissions
      userRoles
    }
  }
`

export const FETCH_LAYERS = gql`
  query FetchLayers($orgId: Int!, $filters: LayersFiltersInputType!) {
    layersList(orgId: $orgId, filters: $filters) {
      ... on LayerListType {
        layersList {
          id
          jsonSchema
          webUiJsonSchema
          name
          title
          geometryType
          geometryColor
          description
          wmsFeaturetypeName
          recordsCount
          userPermissions
          userRoles
        }
      }
      ... on GTLayerListType {
        layersList {
          id
          jsonSchema
          webUiJsonSchema
          title
          geometryType
          geometryColor
          description
          wmsFeaturetypeName
        }
      }
    }
  }
`

export const FETCH_RECORDS = gql`
  query FetchRecords(
    $layerId: Int!
    $filters: LookupsInputType
    $pageInfo: PageInfoInputType
  ) {
    recordsList(layerId: $layerId, filters: $filters, pageInfo: $pageInfo) {
      ... on PointRecordType {
        id
        createdAt
        data
        geometry
        updatedAt
        layer {
          description
          geometryColor
          geometryType
          id
          name
          jsonSchema
          webUiJsonSchema
          title
        }
      }
      ... on PolygonRecordType {
        id
        data
        createdAt
        geometry
        updatedAt
        layer {
          description
          geometryColor
          geometryType
          id
          name
          jsonSchema
          webUiJsonSchema
          title
        }
      }
      ... on LineStringRecordType {
        id
        createdAt
        data
        geometry
        updatedAt
        layer {
          description
          geometryColor
          geometryType
          id
          name
          jsonSchema
          webUiJsonSchema
          title
        }
      }
      ... on MultiPolygonRecordType {
        id
        createdAt
        data
        geometry
        updatedAt
        layer {
          description
          geometryColor
          geometryType
          id
          name
          jsonSchema
          webUiJsonSchema
          title
        }
      }
      ... on MultiPointRecordType {
        id
        createdAt
        data
        geometry
        updatedAt
        layer {
          description
          geometryColor
          geometryType
          id
          name
          jsonSchema
          webUiJsonSchema
          title
        }
      }
      ... on MultiLineStringRecordType {
        id
        createdAt
        data
        geometry
        updatedAt
        layer {
          description
          geometryColor
          geometryType
          id
          name
          jsonSchema
          webUiJsonSchema
          title
        }
      }
    }
  }
`

export const FETCH_USER_DETAILS = gql`
  query FetchUserDetails($userId: ID!, $orgId: ID!) {
    userDetails(userId: $userId, orgId: $orgId) {
      organizations
      email
      firstName
      id
      lastName
      phone
    }
  }
`

export const LAYER_DETAILS = gql`
  query LayerDetails($layerId: Int!) {
    layerDetails(layerId: $layerId) {
      description
      esIndexName
      geometryColor
      geometryType
      id
      jsonSchema
      metaData
      mobileUiJsonSchema
      name
      recordsCount
      title
      webUiJsonSchema
      wmsFeaturetypeName
    }
  }
`

export const RECORD_PAGE_NUMBER = gql`
  query RecordPageNumber($paginationInput: PaginationInput) {
    recordsPagination(paginationInput: $paginationInput) {
      pageNumber
    }
  }
`
